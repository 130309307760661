@use '@angular/material' as mat;
@use 'node_modules/@ngneat/hot-toast/src/styles/styles.scss';
@import 'styles/shared';
@import 'swiper/scss';
@import 'swiper/scss/autoplay';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/grid';
@import 'swiper/scss/scrollbar';

@tailwind base;
@tailwind components;
@tailwind utilities;

@include mat.core();
@include mat.core-theme($light-theme);

@include mat.button-theme($light-theme);
@include mat.icon-button-theme($light-theme);
@include mat.card-theme($light-theme);
@include mat.checkbox-theme($light-theme);
@include mat.chips-theme($light-theme);
@include mat.form-field-theme($light-theme);
@include mat.icon-theme($light-theme);
@include mat.input-theme($light-theme);
@include mat.progress-spinner-theme($light-theme);
@include mat.radio-theme($light-theme);
@include mat.stepper-theme($light-theme);
// Workaround
@include mat.stepper-typography(
  mat.define-legacy-typography-config($font-family: 'inherit')
);
@include mat.toolbar-theme($light-theme);
@include mat.tooltip-theme($light-theme);

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background: #fafafa;
}

.-vertical-content {
  display: flex;
  flex-direction: column;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: 16px;
  padding-right: 16px;

  padding-top: 16px;
  padding-bottom: 16px;
}

.order-status-color {
  &-unpaid {
    color: mat.get-color-from-palette($warn-palette, 500);
  }
  &-paid {
    color: mat.get-color-from-palette($success-palette, 500);
  }
  &-pending {
    color: mat.get-color-from-palette($accent-palette, 500);
  }
  &-canceled {
    color: mat.get-color-from-palette($error-palette, 500);
  }
  &-expired {
    color: mat.get-color-from-palette($error-palette, 500);
  }
  &-failed {
    color: mat.get-color-from-palette($error-palette, 500);
  }
}

.order-status {
  &-unpaid {
    mat-icon,
    mat-icon.mat-mdc-list-item-icon {
      color: mat.get-color-from-palette($warn-palette, 500);
    }
  }
  &-paid {
    mat-icon,
    mat-icon.mat-mdc-list-item-icon {
      color: mat.get-color-from-palette($success-palette, 500);
    }
  }
  &-pending {
    mat-icon,
    mat-icon.mat-mdc-list-item-icon {
      color: mat.get-color-from-palette($accent-palette, 500);
    }
  }
  &-canceled {
    mat-icon,
    mat-icon.mat-mdc-list-item-icon {
      color: mat.get-color-from-palette($error-palette, 500);
    }
  }
  &-expired {
    mat-icon,
    mat-icon.mat-mdc-list-item-icon {
      color: mat.get-color-from-palette($error-palette, 500);
    }
  }
  &-failed {
    mat-icon,
    mat-icon.mat-mdc-list-item-icon {
      color: mat.get-color-from-palette($error-palette, 500);
    }
  }
}

.order-status-background {
  &-unpaid {
    background-color: mat.get-color-from-palette($warn-palette, 500);
  }
  &-paid {
    background-color: mat.get-color-from-palette($success-palette, 500);
  }
  &-pending {
    background-color: mat.get-color-from-palette($accent-palette, 500);
  }
  &-canceled {
    background-color: mat.get-color-from-palette($error-palette, 500);
  }
  &-expired {
    background-color: mat.get-color-from-palette($error-palette, 500);
  }
  &-failed {
    background-color: mat.get-color-from-palette($error-palette, 500);
  }
}

@media print {
  body {
    background: white;
  }

  .show-selector,
  .user-info,
  .actions-column {
    display: none !important;
  }

  tr.mat-mdc-row,
  tr.mat-mdc-footer-row {
    height: 32px !important;
  }
}

.swiper {
  overflow: hidden;
}

.reservaties {
  .mdc-list-item__secondary-text {
    white-space: normal;
  }

  .mat-mdc-icon-button {
    line-height: 24px;
  }

  .mat-icon.mat-icon-inline {
    width: auto;
    flex-shrink: 0;
  }

  .mat-mdc-chip {
    *,
    ::before,
    ::after {
      border-style: none;
    }
  }
}
